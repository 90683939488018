@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fade-in-and-out {
  0% { opacity: 0; }
  25% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: 0; }
}

.fade-in {
  animation: fadeIn .5s ease forwards;
}

.fade-out {
  animation: fadeOut .2s forwards;
}

.fade-in-and-out {
  animation: fade-in-and-out 3s ease-in-out forwards .2s;
}

/* Reduce animations if the user's preference is set to 'reduce motion' */
@media (prefers-reduced-motion: reduce) {
  .fade-in,
  .fade-out,
  .fade-in-and-out {
    animation: none;
  }
}

